
























import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import {imageUrl, showTagRE} from '@/utils/common'
import {base64DownloadFile, ExportExcel} from "@/utils/exportFile";

@Component({})
export default class AUser extends Vue {
  baseUrl = imageUrl
  showTagRE = showTagRE

  name: any = ''


  // 表格数据
  tableData = [];
  // 加载状态
  loading = false;
  // 总条数
  total = 0;
  // 搜索条件
  search: any = {
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 20, //每页数量
    },
  };

  exportLoading = false
  exportD() {
    this.exportLoading = true
    api.post('J27707', {id: this.$route.query.id}).then(res => {
      console.log(res);
      base64DownloadFile(res.fileName, res.fileBody )
    }).finally(() => {
      this.exportLoading = false
    })
  }


  // 搜索 search:true 重置翻页
  searchTable(search = false) {
    this.loading = true;
    if (search) {
      this.search.pagination = {
        pageSize: 20,
        page: 0,
      };
    }
    this.search.id = this.$route.query.id
    api
      .post('J27706', this.search)
      .then(res => {
        this.total = res.total;
        this.tableData = res.list;
      }).finally(() =>{
      this.loading = false;
    })
  }




  activated() {
    this.name = this.$route.query.name
    this.searchTable();
  }
}
